import Head from "next/head"
import { BlitzLayout } from "@blitzjs/next"
import Navbar from "app/components/Navbar"

const LoggedInLayout: BlitzLayout<{ title?: string }> = ({ title, children }) => {
  return (
    <>
      <Head>
        <title>{title || "designOS"}</title>
        <link rel="icon" href="/favicons/favicon.ico" />
      </Head>
      <Navbar />
      <main className="max-w-5xl mx-auto px-6 gap-0 py-8 flex">{children}</main>
    </>
  )
}

export default LoggedInLayout
