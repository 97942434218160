/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import * as HeroIcons from "@heroicons/react/outline"
import { cx } from "@vechaiui/react"

type IconName = keyof typeof HeroIcons

interface DropdownOptions {
  label: string
  href?: string
  action?: () => void
  icon?: IconName
  bold?: boolean
}
interface DropdownProps {
  className?: string
  greeting?: string | React.ReactNode
  options: DropdownOptions[]
  triggerButton: React.ReactNode
}

export default function Dropdown({ triggerButton, options, greeting }: DropdownProps) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>{triggerButton}</Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-neutral-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {greeting && <span className="block p-4 text-sm font-bold">{greeting}</span>}
          {options.map((option) => (
            <div key={option.label} className="py-1">
              <Menu.Item>
                {({ active }) => {
                  const Icon = option.icon ? HeroIcons[option.icon] : null

                  return (
                    <a
                      onClick={option.action}
                      href={option.href}
                      className={cx(
                        active ? "bg-neutral-100 text-neutral-900" : "text-neutral-700",
                        "block px-4 py-2 text-sm cursor-pointer font-semibold"
                      )}
                    >
                      <span className="flex items-center space-x-2">
                        {Icon && <Icon className={cx("w-4 h-4")} />}
                        <span>{option.label}</span>
                      </span>
                    </a>
                  )
                }}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
