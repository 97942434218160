import { Spinner } from "@vechaiui/react"
import { ReactNode, Suspense } from "react"

const SuspenseWithSpinner = ({ children }: { children: ReactNode }) => (
  <Suspense
    fallback={
      <span className="flex items-center justify-center h-screen max-w-sm mx-auto">
        <Spinner />
      </span>
    }
  >
    {children}
  </Suspense>
)

export default SuspenseWithSpinner
