import { useMutation } from "@blitzjs/rpc";
import { Routes } from "@blitzjs/next";
import { Avatar } from "@vechaiui/react"
import logout from "app/auth/mutations/logout"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"
import Dropdown from "./Dropdown"
import sentence from "lodash/startCase"
import Link from "next/link";

const Navbar = () => {
  const currentUser = useCurrentUser()
  const [logoutMutation] = useMutation(logout)
  // const [orgs] = useQuery(fetchOrganizations, {})

  return (
    <nav className="sticky top-0 z-40 backdrop-blur-3xl shadow-sm lg:shadow-none">
      <div className="w-full px-6 mx-auto">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center space-x-3">
            <div className="flex-shrink-0">
              <h1 className="font-medium text-neutral-900 text-md">
                <Link href="/">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10.9969 16.8033C14.3214 16.3204 16.875 13.4584 16.875 10C16.875 6.5416 14.3214 3.67963 10.9969 3.19674C10.7004 3.15368 10.5521 3.13215 10.3988 3.19165C10.2758 3.23941 10.1459 3.35182 10.0809 3.46672C10 3.60986 10 3.78158 10 4.125V15.875C10 16.2184 10 16.3901 10.0809 16.5333C10.1459 16.6482 10.2758 16.7606 10.3988 16.8084C10.5521 16.8679 10.7004 16.8463 10.9969 16.8033Z"
                      fill="currentColor"
                    />
                  </svg>
                </Link>
              </h1>
            </div>

            <div className="flex ml-6 space-x-8">
              <a
                href={Routes.Home().pathname}
                className="inline-flex items-center text-md font-bold text-neutral-900"
                aria-current="page"
              >
                <span>designOS&nbsp;</span>
                <span className="font-medium text-neutral-500">beta</span>
              </a>
            </div>
            {/*
            <div className="flex ml-6 space-x-8">
              <Listbox />
            </div> */}
          </div>

          <div className="flex items-center ml-6 space-x-4">
            {/* <button className="px-3 py-2 text-sm font-bold leading-4 text-neutral-700 bg-white border border-neutral-300 rounded-md shadow-sm hover:bg-neutral-50">
              Upload
            </button> */}

            {currentUser && (
              <Dropdown
                greeting={
                  <span className="flex flex-col">
                    <span>{currentUser.name}</span>
                    {currentUser?.currentOrganization && (
                      <span className="text-sm font-medium text-neutral-500">
                        {sentence(currentUser.currentOrganization.subdomain)}
                      </span>
                    )}
                  </span>
                }
                options={[
                  {
                    label: "Settings",
                    href: Routes.Settings().pathname,
                  },
                  {
                    label: "Feedback",
                    href: "https://discord.gg/7KEhhD8Bkx",
                  },
                  {
                    label: "Sign out",
                    action: () => logoutMutation(),
                  },
                ]}
                triggerButton={
                  <Avatar
                    className="w-8 h-8 rounded-full"
                    src={currentUser?.picture || undefined}
                    name={currentUser?.name || undefined}
                  />
                }
              />
            )}
          </div>
        </div>
      </div>
    </nav>
    // <nav className="sticky top-0 z-10 p-4 bg-white border-b">
    //   <ul className="flex items-center justify-between">
    //     <span className="flex space-x-2">
    //       <img src="/logos/diagram-bw-logo.svg" alt="designos" />
    //       {currentUser && <Listbox />}
    //     </span>
    //     <span className="justify-end">
    //       {currentUser?.picture && (
    //         <span className="space-x-2">
    //           <Avatar
    //             name={currentUser?.name || undefined}
    //             className="w-8 h-8 rounded-full"
    //             src={currentUser?.picture}
    //           />
    //           <Button onClick={() => logoutMutation()} size="xs" className="rounded-full">
    //             <a>Logout</a>
    //           </Button>
    //         </span>
    //       )}
    //     </span>
    //   </ul>
    // </nav>
  )
}

const NavbarSuspenseContainer = () => {
  return <Navbar />
}

export default NavbarSuspenseContainer
