import SuspenseWithSpinner from "app/components/SuspenseWithSpinner"
import LoggedInLayout from "app/core/layouts/LoggedInLayout"
import GalleryHome from "app/gallery/client/components/GalleryHome"
import { BlitzPage } from "@blitzjs/next"

const Home: BlitzPage = () => {
  return (
    <main className="w-full">
      <SuspenseWithSpinner>
        <GalleryHome />
      </SuspenseWithSpinner>
    </main>
  )
}

Home.authenticate = true
Home.suppressFirstRenderFlicker = true
Home.getLayout = (page) => <LoggedInLayout title="Gallery">{page}</LoggedInLayout>

export default Home
