import Link from "next/link"
import { useQuery, useMutation, invalidateQuery } from "@blitzjs/rpc"
import { useSession } from "@blitzjs/auth"
import { Tag, Button } from "@vechaiui/react"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"
import getPostComments from "app/gallery/server/gallery-comments/queries/getPostComments"
import createPostWithUploadUrls from "app/storage/server/mutations/createPostWithUploadUrls"
import analytics, { AnalyticsEvent } from "app/utils/analytics"
import axios from "axios"
import { useState, useCallback, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import createGalleryPost from "../../server/gallery-posts/mutations/createGalleryPost"
import getGalleryPosts from "../../server/gallery-posts/queries/getGalleryPosts"
import GalleryEmpty from "./GalleryEmpty"
import GalleryImageView from "./GalleryImageView"
import { ImageMetadata } from "app/storage/ImageMetadata"
import { z } from "zod"

type Format = z.infer<typeof ImageMetadata>["format"]

const mimeToFormat = (mime: string): Format | undefined => {
  switch (mime) {
    case "image/png":
      return "png"
    case "image/jpeg":
      return "jpeg"
    case "image/gif":
      return "gif"
    case "image/webp":
      return "webp"
    default:
      return undefined
  }
}

const getMetadata = async ({
  file,
  base64Url,
}: {
  file: File
  base64Url: string
}): Promise<z.infer<typeof ImageMetadata>> => {
  const im = new Image()
  im.src = base64Url
  await im.decode()
  const { width, height } = im
  const format = mimeToFormat(file.type)
  if (format === undefined) {
    throw new Error("Unsupported mime type: " + file.type)
  }
  return {
    // TODO: get from file
    format,
    hasAlpha: true,
    width,
    height,
  }
}

const GalleryHome = () => {
  const currentUser = useCurrentUser()
  const session = useSession()

  const [{ galleryPosts }, { refetch }] = useQuery(getGalleryPosts, { take: 30 })
  const [files, setFiles] = useState<{ file: File; previewURL: string }[]>([])
  const [postWithUploadUrls] = useMutation(createPostWithUploadUrls)
  const [createNewGalleryPost] = useMutation(createGalleryPost)

  const { register, handleSubmit, reset } = useForm()

  const onUploadSubmit = (data: { title: string; comment?: string }) => {
    toast.promise(handleFilesUpload(data.title, data.comment), {
      loading: "Uploading...",
      success: "Uploaded to Gallery",
      error: "Something went wrong. Please try again.",
    })
    setFiles([])
  }

  const handleFilesChange = useCallback((acceptedFiles: File[]) => {
    setFiles(
      acceptedFiles.map((file) => ({
        file,
        previewURL: URL.createObjectURL(file),
      }))
    )
  }, [])

  // TODO: Extract this out to its own upload file hook so we can reuse it in other places
  const handleFilesUpload = async (title: string, comment: string | undefined) => {
    try {
      const post = await createNewGalleryPost({ title, comment })

      const withMetadata = await Promise.all(
        files.map(async ({ file, previewURL }) => {
          // If any of the files fail here in getMetadata, eg via an unsupported mime type, we will fail all
          const metadata = await getMetadata({ file, base64Url: previewURL })
          return { file, previewURL, metadata }
        })
      )

      const { nameToUrl } = await postWithUploadUrls({
        postId: post.id,
        files: withMetadata.map(({ file, metadata }) => ({
          metadata,
          fileType: file.type,
          name: encodeURI(file.name),
        })),
      })

      await Promise.all(
        files.map(async ({ file }) => {
          const blob = new Blob([new Uint8Array(await file.arrayBuffer())], {
            type: file.type,
          })

          const fileName = encodeURI(file.name)
          const fileUploadUrl = nameToUrl[fileName]!
          const uploadResponse = await axios.put(fileUploadUrl.uploadUrl!, blob)
          if (![200, 201].includes(uploadResponse.status)) {
            toast.error("Something went wrong")
          }
        })
      )

      invalidateQuery(getGalleryPosts)
      invalidateQuery(getPostComments)
      refetch()
      setFiles([])
      reset({ title: "", comment: "" })
    } catch (e) {
      toast.error("Something went wrong!")
      console.log(e)
    }
  }

  const { getRootProps, getInputProps, isDragActive, fileRejections, rootRef } = useDropzone({
    onDrop: handleFilesChange,
    maxFiles: 5,
    multiple: true,
    maxSize: 100_000_000,
    accept: { "image/*": [] },
  })

  useEffect(() => {
    const fileLength = fileRejections.length
    fileLength > 0 &&
      fileLength <= 5 &&
      toast.error("You can only Upload Images", {
        position: "bottom-center",
      })
    fileLength > 5 && toast.error("Only 5 files at a time, please!", { position: "bottom-center" })
  }, [fileRejections])

  const handleClick = () => {
    rootRef.current?.click()
    analytics.event(AnalyticsEvent.buttonClick, { label: "Upload Button Clicked" })
  }

  if (currentUser) {
    return (
      <>
        <div className="flex items-center justify-between mb-8">
          <span className="inline-flex items-center space-x-3">
            <h1 className="text-lg font-bold">Gallery</h1>
            {session.personal && (
              <Tag className="font-semibold cursor-default select-none">Personal</Tag>
            )}
          </span>

          <Button
            className="px-3 py-2 text-sm font-bold leading-4 bg-white border rounded-md shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50"
            onClick={handleClick}
            loadingText="Uploading..."
          >
            Upload
          </Button>
        </div>

        <>
          {files.length > 0 && (
            <>
              <form
                onSubmit={handleSubmit(onUploadSubmit)}
                className="flex flex-col w-full mx-auto bg-white border shadow-sm rounded-xl"
              >
                <div className="flex items-center justify-between p-6 mx-auto space-x-6 overflow-x-auto rounded-xl">
                  {files.map(({ previewURL }) => (
                    <img
                      key={previewURL}
                      className="h-auto object-fit max-h-64 rounded-xl"
                      src={previewURL}
                    />
                  ))}
                </div>
                <div className="flex items-center justify-between h-16 px-4 py-3 space-x-2 border-t">
                  <input
                    type="text"
                    {...register("title")}
                    required
                    className="w-full p-0 text-sm font-medium border-0 outline-none md:px-2 focus:ring-0 focus:ring-offset-0"
                    placeholder="Title"
                    autoFocus
                  />
                </div>
                <div className="flex items-center justify-between h-16 px-4 py-3 space-x-2 border-t">
                  <input
                    type="text"
                    {...register("comment")}
                    className="w-full p-0 text-sm font-medium border-0 outline-none md:px-2 focus:ring-0 focus:ring-offset-0"
                    placeholder="Description"
                  />

                  <Button
                    variant="ghost"
                    className="rounded-full"
                    onClick={() => setFiles([])}
                    // className="hidden px-4 py-2 text-sm font-bold bg-white border rounded-full shadow-sm md:block text-neutral-700 border-neutral-300 hover:bg-neutral-50"
                  >
                    Cancel
                  </Button>
                  <button
                    type="submit"
                    className="px-4 py-2 text-sm font-bold text-white rounded-full bg-neutral-900 hover:bg-neutral-700"
                  >
                    Share
                  </button>
                </div>
              </form>
              <hr className="my-8" />
            </>
          )}

          <main className="flex-row">
            <div
              id="masonry"
              className="relative max-w-5xl gap-8 mx-auto columns-1 md:columns-2 lg:columns-3"
            >
              <div
                className="flex justify-center p-8 mb-8 border-2 border-dotted cursor-pointer rounded-xl hover:border-neutral-300"
                onClick={handleClick}
                {...getRootProps()}
              >
                <div className="space-y-2 text-center">
                  <svg
                    className="w-12 h-12 mx-auto text-neutral-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <input {...getInputProps()} />
                  <p className="text-sm font-medium text-neutral-400">
                    {isDragActive ? "Drop Here" : "PNG, JPG, or GIF"}
                  </p>
                </div>
              </div>
              {galleryPosts &&
                galleryPosts.map((post) => <GalleryImageView key={post.id} post={post} />)}
              {galleryPosts?.length < 3 && <GalleryEmpty />}
            </div>

            <hr className="my-8" />

            <div className="relative z-30 items-center justify-between w-full px-8 py-6 space-y-4 bg-white shadow-sm flex-column md:flex rounded-3xl md:space-y-0">
              <div className="flex items-center space-x-3">
                <img src="figma.png" className="w-16 h-16" />
                <div className="flex-1 flex-column">
                  <h4 className="font-bold text-md">designOS for Figma</h4>
                  <p className="font-medium text-neutral-500 text-md">Upload directly from Figma</p>
                </div>
              </div>
              <a
                href="https://www.figma.com/community/plugin/1116854429034723530"
                className="block px-4 py-2 font-bold text-center text-white rounded-full bg-neutral-900 text-md hover:bg-neutral-700 md:flex"
              >
                Install on Figma
              </a>
            </div>
          </main>
        </>
      </>
    )
  } else {
    return (
      <>
        <div className="flex justify-center">
          <Link href="/api/auth/google">
            <a className="button small">
              <Button size="sm" className="rounded-lg" variant="solid">
                Login
              </Button>
            </a>
          </Link>
        </div>
      </>
    )
  }
}

export default GalleryHome
