// import logger from "app/utils/logger"
import type EventOptions from "nextjs-google-analytics"
import { event as gaEvent } from "nextjs-google-analytics"
export enum AnalyticsEvent {
  pageView = "page_view",
  buttonClick = "button_click",
}

type EventOptions = {
  category?: string
  label?: string
  value?: number
  nonInteraction?: boolean
  userId?: string
}

export const trackingId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS

export const event = (action: AnalyticsEvent, metadata: EventOptions) => {
  console.log(`Firing new analytics event for ${action}`)
  gaEvent(action, metadata)
}

const analytics = { event, trackingId }

export default analytics
