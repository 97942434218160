const GalleryEmpty = () => {
  return (
    <>
      <div className="absolute bg-gradient-to-b from-neutral-50/[.0] to-neutral-50 w-full h-full z-10"></div>
      <div className="relative w-full mb-8 overflow-hidden border bg-neutral-100 post rounded-xl aspect-square object-fit h-72" />
      <div className="relative w-full mb-8 overflow-hidden border bg-neutral-100 post rounded-xl aspect-square object-fit h-72" />
      <div className="relative w-full mb-8 overflow-hidden border bg-neutral-100 post rounded-xl aspect-square object-fit h-72" />
      <div className="relative w-full mb-8 overflow-hidden border bg-neutral-100 post rounded-xl aspect-square object-fit h-72" />
      <div className="relative w-full mb-8 overflow-hidden border bg-neutral-100 post rounded-xl aspect-square object-fit h-72" />
      <div className="relative w-full mb-8 overflow-hidden border bg-neutral-100 post rounded-xl aspect-square object-fit h-72" />
      <div className="relative w-full mb-8 overflow-hidden border bg-neutral-100 post rounded-xl aspect-square object-fit h-72" />
    </>
  )
}

export default GalleryEmpty
