import Link from "next/link";
import { Routes } from "@blitzjs/next";
import { Avatar } from "@vechaiui/react"
import { getTimeAgo } from "app/utils/dateUtils"
import { PostWithImageUrls } from "../../types"

const GalleryImageView = ({ post }: { post: PostWithImageUrls }) => {
  return (
    <Link
      passHref
      href={Routes.GalleryPostContainer({
        postId: post.id,
        imageId: post.images[0]?.id!,
      })}
    >
      <div className="flex flex-col mb-8 space-y-3 break-inside-avoid-column">
        <a className="relative flex w-full overflow-hidden border cursor-pointer bg-neutral-100 post rounded-xl">
          <div className="relative flex items-center w-full">
            <img
              src={post.images[0]?.assets.find((asset) => asset.assetQuality === "FULL")?.url!}
              className="m-auto post-image"
            />
          </div>
        </a>

        <div className="flex items-center justify-between w-full text-sm">
          <div className="flex items-center justify-between w-full space-x-2 text-sm">
            <div className="flex items-center space-x-2">
              <Avatar
                name={post.createdBy.user?.name!}
                src={post.createdBy.user?.picture!}
                className="w-6 h-6 rounded-full"
              />
              <p className="font-bold">{post.createdBy.user?.name!}</p>
            </div>
            <p className="font-medium text-neutral-500">{getTimeAgo(post.createdAt, false)}</p>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default GalleryImageView
